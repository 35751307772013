.container {
  background: linear-gradient(to bottom, #549b19, #f5f7fa); /* Light green fading to white */
  padding: 40px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.titleContainer {
  max-width: 700px;
  text-align: center;
}

.title {
  color: #2c3e50;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle {
  color: #606969;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
}

.input {
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

/* Ensure select elements match other inputs */
select.input {
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 15px;
  padding-right: 45px;
}

.input:focus {
  border-color: #78bc44;
  outline: none;
  box-shadow: 0 4px 12px rgba(120, 188, 68, 0.3);
}

.button {
  padding: 15px 30px;
  border-radius: 6px;
  border: none;
  background-color: #78bc44;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
}

.button:hover {
  background-color: #66a83a;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.confirmationMessage {
  width: 100%;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f7fa;
  padding: 20px;
  font-size: 1.2rem;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .form {
    width: 100%;
  }

  .input {
    width: 90%; /* Reduce input width on mobile */
    max-width: 350px; /* Optional: set a maximum width */
    margin: 0 auto; /* Center inputs */
  }

  .button {
    width: 90%;
    max-width: 350px; /* Optional: set a maximum width for button */
    margin: 0 auto;
  }
}
